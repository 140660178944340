/*------------------------------------*\
    # views.login
\*------------------------------------*/

.login {
    display: flex;
    height: 80vh;
    align-items: center;
}

.login__wrapper {
    width: 100%;
    max-width: 320px;
}

.login__header {
    margin-bottom: calc($unit * 10);
}

.login__btn {
    width: 100%;
}
