/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-pos {
}

.u-pos--rel {
    position: relative;
}

.u-pos--abs {
    position: absolute;
}
