/*------------------------------------*\
    # component.settings-itemlist
\*------------------------------------*/

.settings-itemlist {
}

.settings-itemlist__header {
    display: grid;
    grid-gap: 24px;
    padding: 0 0 calc($unit * 2) 0;

    opacity: 0.4;

    @mixin type-scale $type-micro, 4;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

/*
 Since list items in settings have different columns we need to define
 a grid-template differently to match the contents of the grid table
 */

.settings-itemlist__header--services {
    grid-template-columns: minmax(80px, auto) 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__header--units {
    grid-template-columns: 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__header--clients {
    grid-template-columns: 1fr minmax(180px, auto);
}

/* List items */

.settings-itemlist__item {
    display: grid;
    grid-gap: 24px;

    padding: calc($unit * 6) 0;
    border-bottom: 1px solid color($color-text a(0.1));
}

.settings-itemlist__item--services {
    grid-template-columns: minmax(80px, auto) 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__item--units {
    grid-template-columns: 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__item--clients {
    grid-template-columns: 1fr minmax(180px, auto);
}
