/*------------------------------------*\
    # views.errorpage
\*------------------------------------*/

.errorpage {
}

.errorpage__wrapper {
    max-width: 600px;
}

.errorpage__header {
    padding: calc($unit * 20) 0;
}

.errorpage__content {
    padding-bottom: calc($unit * 10);
}

.errorpage__action {
}
