/*------------------------------------*\
    # block.header
\*------------------------------------*/

.header {
    display: flex;
    justify-content: space-between;
    min-height: 72px;

    background: $color-black;
}

.header__branding {
    color: white;
    padding: calc($unit * 6) 0 0 calc($unit * 6);
}
