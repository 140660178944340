/*------------------------------------*\
    # block.modal
\*------------------------------------*/

.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow-y: scroll;
    overflow-x: hidden;

    width: 100%;

    pointer-events: none;
    opacity: 0;
    transition: all 0.1s;

    background-color: color($color-secondary a(0.4));

    @media (--screen-from-sml) {
        padding: 10% 0;
    }

    &.is-visible {
        opacity: 1;
        pointer-events: all;
        transform: scale(1);
    }
}

.modal__wrapper {
    position: relative;
    z-index: 2;

    margin: 0 auto;

    background-color: white;
    box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, 0.4);

    transition: all 0.2s;

    @media (--screen-from-sml) {
        width: 50%;
        min-width: 280px;
        border-radius: $global-radial;
    }
}

.modal__content {
    padding: calc($unit * 8);
}

.item__title {
    position: relative;
    bottom: calc($unit * 2);
}

.modal__header {
    padding: calc($unit * 8);
}

.modal__footer {
    padding: 0 calc($unit * 8) calc($unit * 8) calc($unit * 8);
}

.item__description {
    font-weight: $type-weight-bold;
    position: relative;
    top: calc($unit * 5);
}

.modal__title {
}

.modal__subtitle {
    color: color(white a(0.7));
    margin-top: calc($unit * 2);
    @mixin type-scale $type-base, 5;
}

.modal__close {
    position: absolute;
    z-index: 2;
    top: 0px;
    right: 0px;

    width: 60px;
    height: 60px;

    color: black;
    background: color(white a(0.1));
    border-radius: 0;

    transition: all 0.2s;

    svg {
        fill: color($color-text a(0.5));
    }

    &:hover,
    &:focus {
        svg {
            fill: $color-warning;
        }
    }
}
