/*------------------------------------*\
    # component.language-changer
\*------------------------------------*/

.language-changer {
    display: flex;
}

.language-changer__item {
}

.language-changer__item--active {
}
