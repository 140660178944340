/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */

html {
    @mixin typomatic-init;
    background: $color-black;
}

body {
    position: relative;

    background: $color-bg;

    font-family: $ff-sans;
    font-weight: $type-weight-regular;
    color: $color-text;

    /* Enable font smoothing for WebKit */

    -webkit-font-smoothing: antialiased;

    /* Lock scrolling on mobile nav open */
    &.is-locked {
        @media (--screen-to-lrg) {
            overflow: hidden;
        }
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    max-width: 100%;
}

/* All elements that are not button or anchor but are handled as links to have a pointer */
[role='button'] {
    cursor: pointer;
}
