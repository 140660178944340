/*------------------------------------*\
    # views.proposal
\*------------------------------------*/

.proposal {
}

.proposal__header {
    position: relative;
    z-index: 1;
    padding: 10vh 0 10vh 0;

    background-size: cover;
    background-position: center center;
    background-color: $color-dark;

    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        background: linear-gradient(
            to top,
            color($color-black a(0.6)) 0%,
            color($color-black a(0.4)) 100%
        );

        width: 100%;
        height: 100%;
    }
}

.proposal__header__heading {
    margin-bottom: calc($unit * 30);

    @media print {
        & p,
        h1 {
            color: black;
        }
    }
}

.proposal__details {
    padding-top: 5vh;
}

.proposal__to {
    position: relative;
    z-index: 1;
    padding-top: calc($unit * 5);
    border-top: 1px solid color(white a(0.4));
    color: white;
    opacity: 0.6;

    @media print {
        color: black;
        opacity: 1;
    }
}

.proposal__to__client {
    max-width: 300px;
}

.proposal__total {
    padding-top: calc($unit * 5);

    @media (--screen-from-sml) {
        padding-top: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.proposal__total__list {
    @media (--screen-from-lrg) {
        lost-column: 6/12;
        lost-move: 6/12;
    }
}

.proposal__total__list__item {
    display: flex;
    padding: calc($unit * 1) 0;
}

.proposal__total__list__item--total {
    border-top: 1px dashed color(black a(0.2));
    padding-top: calc($unit * 4);
    margin-top: calc($unit * 5);
}

.proposal__total__list__item--total .proposal__total__list__label {
    padding-top: calc($unit * 2);
}

.proposal__total__list__label {
    flex: 1;
}

.proposal__total__list__value {
    flex: 1;
    text-align: right;
}

.proposal__footer {
    padding-top: calc($unit * 5);
    margin-top: calc($unit * 10);

    @media (--screen-from-sml) {
        lost-flex-container: row;

        border-top: 1px solid color($color-dark a(0.2));
        padding-top: calc($unit * 10);
        margin-top: calc($unit * 30);
    }
}

.proposal__footer__companyinfo {
    display: flex;
}

.proposal__footer__companyinfo__branding {
    max-width: 72px;
    margin-right: calc($unit * 8);
}

.proposal__footer__companyinfo__info {
    max-width: 300px;
}
