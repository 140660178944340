/*------------------------------------*\
    # component.proposal-service
\*------------------------------------*/

.proposal-service {
    position: relative;
    background: white;
    margin-bottom: calc($unit * 8);
}

.proposal-service__dragger {
    position: absolute;
    top: 50%;
    left: calc($unit * -10);
    transform: translateY(-50%);

    width: 20px;
    height: 20px;

    opacity: 0.3;
    cursor: pointer;

    transition: all 0.15s ease-out;

    &:hover {
        opacity: 0.7;
    }
}

.proposal-service__body {
    padding: calc($unit * 8);
}

.proposal-service__body__layout {
    lost-flex-container: row;
}

.proposal-service__body__layout__description {
    lost-column: 5/12;
}

.proposal-service__body__layout__fields {
    lost-column: 4/12;
    lost-flex-container: row;
    justify-items: flex-start;
}

.proposal-service__body__layout__fields .field {
    lost-column: 1/2;
}

.proposal-service__body__layout__summary {
    lost-column: 3/12;
    display: flex;
    align-items: flex-end;
}

.proposal-service__footer {
    border-top: 1px solid color($color-dark a(0.1));
    padding: calc($unit * 5) 0 0;
    margin: calc($unit * 7) 0 0 0;

    display: flex;
    justify-content: flex-end;
}
