/*------------------------------------*\
    # views.settings
\*------------------------------------*/

.settings {
}

.settings__header {
    display: flex;
    justify-content: space-between;

    margin-bottom: calc($unit * 10);
    padding-bottom: calc($unit * 10);
    border-bottom: 1px solid color($color-dark a(0.2));
}

.settings__header__content {
    padding-right: 32px;
    max-width: 500px;
}

.settings__header__action {
}

.settings__layout {
    lost-flex-container: row;
    padding-top: calc($unit * 10);
}

.settings__layout__aside {
    lost-column: 3/12;
    border-right: 1px solid color($color-dark a(0.1));
}

.settings__layout__content {
    lost-column: 8/12;
    lost-move: 1/12;
}
