/*------------------------------------*\
    # tools.shadow
\*------------------------------------*/

/**
 * Mixin name: shadow
 * Description: creates a nice shadow
 * Parameters: 
 * does not take parameters
 */
@define-mixin shadow {
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.04);
}
