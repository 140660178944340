/*------------------------------------*\
    # utils.color
\*------------------------------------*/

.u-color {
}

.u-color--success {
    color: $color-success;
}

.u-color--warning {
    color: $color-warning;
}

.u-color--note {
    color: $color-note;
}

.u-color--bg {
    background-color: $color-bg;
}
