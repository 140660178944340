/*------------------------------------*\
    # views.proposal-create
\*------------------------------------*/

.proposal-create {
}

.proposal-create__heading {
    transition: all 0.1s;
    cursor: pointer;

    &:hover,
    &:focus {
        background: color(rgba(0, 0, 0, 0.1));
    }
}

.proposal-create__subheading {
    transition: all 0.1s;
    cursor: pointer;

    &:hover,
    &:focus {
        background: color(rgba(0, 0, 0, 0.1));
    }
}

.proposal-create__add-block {
    display: flex;
    margin: calc($unit * 10) 0;
}

.proposal-create__save-block {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: calc($unit * 5);
    background: $color-black;
}

.proposal-create__saved {
    position: absolute;
    top: 20px;
    right: 20px;
    background: $color-success;
    padding: calc($unit * 2) calc($unit * 6);
    border-radius: $global-rounded;
    color: white;
}
