/*------------------------------------*\
    # utils.flex
\*------------------------------------*/

.f {
    display: flex;
}

.f--between-center {
    justify-content: space-between;
    align-items: center;
}
