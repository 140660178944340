/*------------------------------------*\
    # component.alert
\*------------------------------------*/

.alert {
    display: block;
    @mixin type-scale $type-small,
    5;
    padding: calc($unit * 3);
    border-radius: $global-radial;
}

.alert--success {
    color: $color-success;
    background: color($color-success a(0.1));
}

.alert--warning {
    color: $color-warning;
    background: color($color-warning a(0.1));
}

.alert--note {
    color: $color-note;
    background: color($color-note a(0.1));
}