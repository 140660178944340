/*------------------------------------*\
    # views.offers
\*------------------------------------*/

.offers {
}

.offers__filters {
    margin-top: calc($unit * 12);
    margin-bottom: calc($unit * 8);
    display: flex;
    justify-content: space-between;
}

.offers__filters__title {
}

.offers__filters__status button,
.offers__filters__order button,
.offers__filters__year button {
    margin-right: calc($unit * 2);
}
