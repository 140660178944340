/*------------------------------------*\
    # block.subheader
\*------------------------------------*/

.subheader {
    padding: 5vh 0 2.5vh 0;
    background: $color-black;
}

.subheader__wrapper {
}

.subheader__split {
    display: flex;
    justify-content: space-between;
}

.subheader__links {
    display: flex;
    justify-content: space-between;

    @mixin type-scale $type-xlarge, 10;
    color: color(white a(0.35));
}

.subheader__links {
    margin-left: calc($unit * -4);
}

.subheader__links__item {
    display: block;
    padding: calc($unit * 2) calc($unit * 4);
    transition: all 0.1s ease-out;

    &:hover,
    &:focus {
        color: color(white a(0.8));
    }
}

.subheader__links__item--active {
    color: color(white a(0.8));
    pointer-events: none;
}

.subheader__cta {
    padding-top: calc($unit * 3);
}
