/*------------------------------------*\
    # views.services
\*------------------------------------*/

.services {
}

.services__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid color(black a(0.2));
}
