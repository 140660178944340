/*------------------------------------*\
    # block.asidenav
\*------------------------------------*/

.asidenav {
}

.asidenav__list {
    margin-bottom: calc($unit * 10);
}

.asidenav__list__item {
}

.asidenav__list__link {
    position: relative;
    display: block;
    color: color($color-text a(0.6));
    padding: calc($unit * 3) 0;
    transition: all 0.2s ease-out;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        background: $color-primary;
        width: 1px;

        opacity: 0;
        transition: all 0.2s linear;
    }

    &:hover,
    &:focus {
        color: $color-primary;

        &:after {
            opacity: 1;
        }
    }

    &.active {
        color: $color-primary;

        &:after {
            opacity: 1;
        }
    }
}
