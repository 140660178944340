/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.d {
}

.d--b {
    display: block;
}

.d--ib {
    display: inline-block;
}

.d--flex {
    display: flex;
}

.d--none {
    display: none;
}
