/*------------------------------------*\
    # component.photobox
\*------------------------------------*/

.photobox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 320px;
    background-position: center center;
    background-size: cover;
    border: 1px dashed color($color-dark a(0.3));
}

.photobox__instructions {
    flex: 1;
    max-width: 50%;
    text-align: center;
}

.photobox__delete {
    position: absolute;
    z-index: 2;
    top: calc($unit * 6);
    right: calc($unit * 6);

    color: white;
}
