/*------------------------------------*\
    # plugins.dropzone
\*------------------------------------*/

.dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropzone p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: $type-weight-bold;
    @mixin type-scale $type-medium, 9;
}

.dropzone img {
    height: 100%;
    width: auto;
}
