/*------------------------------------*\
    # block.nav
\*------------------------------------*/

.nav {
    display: flex;
}

.nav__links {
    display: flex;
    justify-content: space-between;
}

.nav__logout {
    color: $color-warning;
}

.nav__link {
    display: block;
    color: color(white a(0.6));
    padding: calc($unit * 6);

    &:hover,
    &:focus {
        color: white;
    }
}
