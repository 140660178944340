/*------------------------------------*\
    # component.message
\*------------------------------------*/

.message {
    display: block;
    @mixin type-scale $type-small, 0.75;
}

.message--sml {
    padding: calc($unit * 4);
}

.message--med {
    padding: calc($unit * 6);
}

.message--lrg {
    padding: calc($unit * 10);
}

.message__title {
    margin-bottom: calc($unit * 3);
}

.message__content {
    margin-bottom: calc($unit * 5);
}

.message--info {
    background: $color-primary;
    color: white;
    border-radius: 8px;
}
