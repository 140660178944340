/*------------------------------------*\
    # block.proposal-block-service
\*------------------------------------*/

.proposal-block-service {
    padding: calc($unit * 6);
    background: white;
    margin-bottom: 2.5vh;

    @media (--screen-to-sml) {
        margin-right: calc($unit * -8);
        margin-left: calc($unit * -8);
    }

    @media (--screen-from-sml) {
        @mixin shadow;

        border-radius: $global-radial-large;
    }

    @media (--screen-from-lrg) {
        padding: 0 calc($unit * 10);
        lost-flex-container: row;
    }
}

.proposal-block-service__name {
    margin-bottom: calc($unit * 5);
}

.proposal-block-service__info {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 8);
    }
    @media (--screen-from-lrg) {
        lost-column: 5/12;
        padding: calc($unit * 10) calc($unit * 8) calc($unit * 10) 0;
        border-right: 1px solid color($color-dark a(0.1));
    }
}

.proposal-block-service__pricing {
    @media (--screen-from-lrg) {
        lost-column: 7/12;
        padding: calc($unit * 10) 0;
    }
}

.proposal-block-service__pricing__top {
    display: flex;
    align-items: flex-end;
    margin-bottom: calc($unit * 6);
    padding-bottom: calc($unit * 2);
    border-bottom: 1px solid color($color-dark a(0.1));
}

.proposal-block-service__pricing__top__quantity {
    flex: 2;
}

.proposal-block-service__pricing__top__price {
    flex: 1;
}

.proposal-block-service__pricing__top__totalvalue {
    flex: 2;
    text-align: right;
}

.proposal-block-service__pricing__discount {
    display: flex;
    align-items: flex-end;
    margin-bottom: calc($unit * 6);
    padding-bottom: calc($unit * 2);
    border-bottom: 1px solid color($color-dark a(0.1));
}

.proposal-block-service__pricing__discount > div {
    flex: 1;
}

.proposal-block-service__pricing__subtotal {
    display: flex;
    align-items: flex-end;
    margin-bottom: calc($unit * 4);
}

.proposal-block-service__pricing__subtotal > div {
    flex: 1;
}

.proposal-block-service__title {
}

.proposal-block-service__description {
}
