/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1216px;
    margin: 0 auto;

    padding: 0 calc($unit * 8);
}

.wrapper--narrow {
    max-width: 1100px;
}

/* Used in proposal public view, do not change */
.wrapper--tight {
    max-width: 1000px;
}
