/*------------------------------------*\
    # utils.type
\*------------------------------------*/

/* Alignment */
.t-left {
    text-align: left;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

/* Weight */
.t-light {
    font-weight: $type-weight-light;
}

/* Case */
.t-upper {
    text-transform: uppercase;
}

.t-upperext {
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

/* Sizes */
.t-alpha {
    @mixin type-scale $type-huge, 20;
}

.t-beta {
    font-size: responsive 31px 49px; /* $type-xlarge, $type-xxxlarge */
    font-range: 320px 1440px; /* viewport widths between which font-size is fluid */

    line-height: responsive 36px 52px;
    line-height-range: 320px 1440px;
}

.t-gamma {
    font-size: responsive 25px 39px; /* $type-large, $type-xxlarge */
    font-range: 320px 1440px; /* viewport widths between which font-size is fluid */

    line-height: responsive 32px 44px;
    line-height-range: 320px 1440px;
}

.t-delta {
    font-size: responsive 20px 31px; /* $type-medium, $type-xlarge */
    font-range: 320px 1440px; /* viewport widths between which font-size is fluid */

    line-height: responsive 24px 36px;
    line-height-range: 320px 1440px;
}

.t-epsilon {
    font-size: responsive 16px 25px; /* $type-base, $type-large */
    font-range: 320px 1440px; /* viewport widths between which font-size is fluid */

    line-height: responsive 24px 32px;
    line-height-range: 320px 1440px;
}

.t-zeta {
    font-size: responsive 16px 20px; /* $type-base, $type-medium */
    font-range: 320px 1440px; /* viewport widths between which font-size is fluid */

    line-height: responsive 24px 28px;
    line-height-range: 320px 1440px;
}

.t-eta {
    font-size: responsive 14px 16px; /* $type-small, $type-base */
    font-range: 320px 1440px; /* viewport widths between which font-size is fluid */

    line-height: responsive 20px 24px;
    line-height-range: 320px 1440px;
}

.t-small {
    @mixin type-scale $type-small, 5;
}

.t-tiny {
    @mixin type-scale $type-tiny, 4;
}

.t-micro {
    @mixin type-scale $type-micro, 4;
}

/* Colors */
.t-primary {
    color: $color-primary;
}

.t-secondary {
    color: $color-secondary;
}

.t-negative {
    color: white;
}

.t-faded {
    color: color($color-text a(0.5));
}

/* Shorten long links */
.t-shorten {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
}

.t-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
}
