/**
* CSS name convention: BEM
* CSS linting: .stylelintrc
* Global indentation: 4 spaces
* Global units: px
* Browser support: last 3 versions of modern browsers
* Additional notes:
*/

/**
* Import: vars
* Description: variables
*/

/*------------------------------------*\
    # vars.globals
\*------------------------------------*/

/**
 * The $unit variable is a global variable to be used
 * in paddings, margins, for sizing and positioning
 */

/**
 * The $spacer variable is a global variable to be used
 * in to create a unified spacer for all kinds of elements
 */

/**
 * Global class prefix - usage: .$(global-prefix)classname
 */

/**
 * Global radius and rounded
 */

/**
 * Grid
 */

/*------------------------------------*\
    # vars.typography
\*------------------------------------*/

/**
 * Project base line height (unitless in order to work with typomatic)
 */

/**
 * Type scale sizes must be entered in their pixel size
 * (unitless in order to work with typomatic)
 * Default type scale ratio: 1.333;
 */

/** 
 * Type weights
 */

/**
 * Font stacks
 */

/*------------------------------------*\
    # vars.responsive
\*------------------------------------*/

/* 544px */

/* 543px */

/* 768px */

/* 767px */

/* 992px */

/* 991px */

/* 1200px */

/* 1199px */

/* 1440px */

/* 1439px */

/*------------------------------------*\
    # vars.colors
\*------------------------------------*/

/**
 * Brand colors
 */

/**
 * Global text color
 */

/**
 * Theme colors
 */

/**
 * Utility colors
 */

/**
* Import: tools
* Description: tools like typomatic and other mixins / libraries
*/

/*------------------------------------*\
    # tools.typomatic
\*------------------------------------*/

/**
 * Mixin name: typomatic-init
 * Description: creates a vertical rhythm on a page using font-size
 * and line-height on the html element
 * Parameters: 
 * does not take parameters
 */

/**
 * Mixin name: type-scale
 * Description: type-scale sets the type to baseline to achieve
 * vertical rhythm.
 * Parameters: 
 * $scale ($base-font-size is default) - font size (unitless) variable
 * $baselines (1 is default) - number of baselines
 */

/*------------------------------------*\
    # tools.shadow
\*------------------------------------*/

/**
 * Mixin name: shadow
 * Description: creates a nice shadow
 * Parameters: 
 * does not take parameters
 */

/**
* Import: generic
* Description: normalize or reset CSS, box sizing
*/

/*------------------------------------*\
    # generic.boxsizing
\*------------------------------------*/

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}

html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/**
* Import: base
* Description: base structural stylings for setting the body, typography
* and other base styles (no classes should be added here)
*/

/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */

html {
    font-size: 100%;
    line-height: 1.5;
    background: #1a191c;
}

body {
    position: relative;

    background: #f8f8f8;

    font-family: 'Aeonik', Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #333;

    /* Enable font smoothing for WebKit */

    -webkit-font-smoothing: antialiased;
}

/* Lock scrolling on mobile nav open */

@media (max-width: 61.9375em) {

body.is-locked {
            overflow: hidden
    }
        }

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    max-width: 100%;
}

/* All elements that are not button or anchor but are handled as links to have a pointer */

[role='button'] {
    cursor: pointer;
}

/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
}

h1 {
    font-size: 31px;
    line-height: 40px;
}

h2 {
    font-size: 25px;
    line-height: 36px;
}

h3 {
    font-size: 20px;
    line-height: 28px;
}

h4,
h5,
h6 {
    font-size: 16px;
    line-height: 24px;
}

/**
 * Paragraphs
 */

p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
}

/**
 * Lists (ol, ul, dd)
 */

ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}

ol {
}

ol li {
}

ul {
}

ul li {
}

dd {
}

dl dt {
}

dl dd {
}

/**
 * Anchors
 */

a {
    color: #8039ff;
    text-decoration: none;
}

a:link {
    }

a:hover {
        color: rgb(106, 56, 191);
    }

a:focus {
        color: rgb(106, 56, 191);
    }

a:visited {
    }

/**
 * Typographic details
 */

hr {
    padding: 8px 0;
    border: 0;
    border-bottom: 1px solid rgb(222, 222, 222);
}

em {
}

b,
strong {
    font-weight: 700;
}

address {
    font-style: normal;
}

small {
}

pre {
}

code {
}

sub {
}

sup {
}

strike {
}

/**
 * Tables
 */

table th {
        text-align: left;
    }

/**
* Import: component
* Description: cross project reusable components
*/

/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    position: relative;
}

/* Container that holds the whole backend app */

.container--app {
    min-width: 920px;
}

/* Container that holds the public prosal */

.container--public {
}

.container--dark {
    background-color: #182a3f;
}

.container--empty {
    height: 100vh;
}

/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1216px;
    margin: 0 auto;

    padding: 0 32px;
}

.wrapper--narrow {
    max-width: 1100px;
}

/* Used in proposal public view, do not change */

.wrapper--tight {
    max-width: 1000px;
}

/*------------------------------------*\
    # component.logo
\*------------------------------------*/

.logo {
}

/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    -ms-touch-action: manipulation;

        touch-action: manipulation;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    /**
     * Remove border radius on iOS buttons
     */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-radius: 4px;
    font-family: 'Aeonik', Helvetica, Arial, sans-serif;

    -webkit-transition: all 0.15s ease-out;

    transition: all 0.15s ease-out;
}

.btn:focus {
        outline: 0;
    }

/**
     * Disabled
     */

.btn.is-disabled,
    .btn:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

.btn.is-disabled {
        pointer-events: none;
    }

.btn--core {
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    letter-spacing: initial;
    border: 0;
    border-radius: 0;
    padding: 0;
    font-family: 'Aeonik', Helvetica, Arial, sans-serif;
}

.btn--text {
    color: #8039ff;
}

.btn--text:hover,
    .btn--text:focus {
        color: rgb(226, 209, 255);
    }

.btn--basic {
    color: rgb(128, 128, 128);
}

.btn--basic:hover,
    .btn--basic:focus {
        color: rgb(179, 179, 179);
    }

.btn--filter {
    color: rgba(51, 51, 51, 0.6);
    font-size: 14px;
    line-height: 16px;
}

.btn--filter--active {
    color: #8039ff;
    font-weight: 700;
}

/**
 * Button styles
 */

.btn--primary {
    position: relative;
    border: 1px solid rgba(128, 57, 255, 0.2);
    color: white;
    background-color: #8039ff;
    -webkit-box-shadow: 0 2px 10px rgba(128, 57, 255, 0.3);
            box-shadow: 0 2px 10px rgba(128, 57, 255, 0.3);
}

.btn--primary:hover,
    .btn--primary:focus {
        background-color: rgb(110, 56, 204);
        color: white;
        -webkit-box-shadow: 0 2px 15px rgba(128, 57, 255, 0.5);
                box-shadow: 0 2px 15px rgba(128, 57, 255, 0.5);
    }

.btn--dark {
    position: relative;
    color: white;
    background-color: rgb(39, 39, 39);
    -webkit-box-shadow: 0 2px 10px rgba(45, 45, 45, 0.3);
            box-shadow: 0 2px 10px rgba(45, 45, 45, 0.3);
}

.btn--dark:hover,
    .btn--dark:focus {
        color: white;
        -webkit-box-shadow: 0 2px 15px rgba(45, 45, 45, 0.5);
                box-shadow: 0 2px 15px rgba(45, 45, 45, 0.5);
    }

.btn--negative {
    position: relative;
    color: #333;
    background-color: white;
    -webkit-box-shadow: 0 2px 10px rgba(45, 45, 45, 0.1);
            box-shadow: 0 2px 10px rgba(45, 45, 45, 0.1);
}

.btn--negative:hover,
    .btn--negative:focus {
        background-color: rgba(255, 255, 255, 0.8);
        color: #333;
        -webkit-box-shadow: 0 2px 15px rgba(45, 45, 45, 0.2);
                box-shadow: 0 2px 15px rgba(45, 45, 45, 0.2);
    }

.btn--ghost {
    position: relative;
    color: #333;
    background-color: transparent;
}

.btn--ghost:hover,
    .btn--ghost:focus {
        background-color: rgba(45, 45, 45, 0.05);
        color: #333;
    }

/**
 * Spacer - a placeholder for text between
 * two or more buttons
 */

.btn-spacer {
    padding: 0 4px;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: rgb(170, 170, 170);
}

/**
 * Button sizes
 */

.btn--tny {
    font-size: 13px;
    line-height: 16px;
    height: 32px;
    padding: 0 16px;
}

.btn--tny--iconOnly {
        padding: 0 8px;
    }

.btn--sml {
    font-size: 13px;
    line-height: 16px;
    height: 40px;
    padding: 0 24px;
}

.btn--sml--iconOnly {
        padding: 0 12px;
    }

.btn--med {
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    padding: 0 32px;
}

.btn--med--iconOnly {
        padding: 0 16px;
    }

.btn--lrg {
    font-size: 16px;
    line-height: 24px;
    height: 44px;
    padding: 0 32px;
}

.btn--lrg--iconOnly {
        padding: 0 16px;
    }

/**
 * Button icons
 */

.btn__icon {
}

.btn__icon--left {
    margin-right: 16px;
}

.btn__icon--right {
    margin-left: 16px;
}

/*------------------------------------*\
    # component.btn-group
\*------------------------------------*/

.btn-group {
    /**
     * Btngroup base
     */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.btn-group--simple .btn {
    margin-right: 8px;
}

.btn-group--simple .btn:last-child {
        margin-right: 0;
    }

/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;

    display: inline-block;
    vertical-align: middle;
    width: 100%;

    /* Prefixed -webkit to remove border radius on iOS */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;
    font-family: 'Aeonik', Helvetica, Arial, sans-serif;

    border-radius: 4px;
    background: rgba(45, 45, 45, 0.05);
    color: #333;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;

    /* Default input sizes. Add input--sml for small override, etc.  */
    font-size: 16px;
    line-height: 16px;
    height: 40px;
    line-height: 24px;
    -webkit-box-shadow: 0 0 10px rgba(45, 45, 45, 0.05) inset;
            box-shadow: 0 0 10px rgba(45, 45, 45, 0.05) inset;
}

.input::-webkit-input-placeholder {
        color: rgba(51, 51, 51, 0.3);
    }

.input::-moz-placeholder {
        color: rgba(51, 51, 51, 0.3);
    }

.input::-ms-input-placeholder {
        color: rgba(51, 51, 51, 0.3);
    }

.input::placeholder {
        color: rgba(51, 51, 51, 0.3);
    }

.input:focus {
        border-color: transparent;
        background: rgba(45, 45, 45, 0.1);
        outline: 0;
    }

.input:focus::-webkit-input-placeholder {
            color: rgba(51, 51, 51, 0.5);
        }

.input:focus::-moz-placeholder {
            color: rgba(51, 51, 51, 0.5);
        }

.input:focus::-ms-input-placeholder {
            color: rgba(51, 51, 51, 0.5);
        }

.input:focus::placeholder {
            color: rgba(51, 51, 51, 0.5);
        }

/* Input sizes - not used still in the project, but will need to make microforms etc. */

/* .input--sml {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 6);
    line-height: calc($unit * 6);
} */

/* Input widths */

.input--w-micro {
    max-width: 72px;
}

/* Input styles */

.input--dark {
    color: rgba(255, 255, 255, 0.7);
    background: #1a191c;
    border: 1px solid rgba(255, 255, 255, 0.13);
}

.input--dark:hover,
    .input--dark:focus {
        border-color: rgba(255, 255, 255, 0.7);
    }

.input--dark:focus {
        -webkit-box-shadow: 0 0 5px black;
                box-shadow: 0 0 5px black;
    }

/* Input types */

.input--text {
    padding: 8px 16px;
}

/* Supersized inputs with fluid typography */

.input--supersized {
    /* Reset default styles */
    height: auto;
    line-height: auto;

    font-size: calc(16.85714px + 0.98214vw); /* $type-medium, $type-xlarge */ /* viewport widths between which font-size is fluid */

    line-height: calc(20.57143px + 1.07143vw);
}

@media screen and (min-width: 1440px) {

    .input--supersized {
        line-height: 36px;
    }
}

@media screen and (max-width: 320px) {

    .input--supersized {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .input--supersized {
        font-size: 31px;
    }
}

@media screen and (max-width: 320px) {

    .input--supersized {
        font-size: 20px;
    }
}

.input--supersized--negative {
    color: white;
}

.input--number {
    padding-right: 0;
}

.input--select {
    padding: 8px 16px;
}

.input--select--multiple {
    min-height: 160px;
}

.input--textarea {
    min-height: 160px;
    resize: vertical;
    padding: 16px;
    white-space: pre-wrap;
}

.input--radio + label:before {
            border-radius: 50%;
        }

.input--check,
.input--radio {
    position: absolute;
    left: -99999px;
    opacity: 0;
}

.input--check + label, .input--radio + label {
        padding-left: 4px;
    }

.input--check + label:before, .input--radio + label:before {
            content: '';
            display: block;
            float: left;
            width: 12px;
            height: 12px;
            margin-top: 6px;

            -webkit-transition: all 0.2s ease-in-out;

            transition: all 0.2s ease-in-out;

            border: 1px solid rgb(196, 196, 196);
        }

.input--check:focus + label:before, .input--radio:focus + label:before {
                -webkit-box-shadow: 0 0 5px #8039ff;
                        box-shadow: 0 0 5px #8039ff;
            }

.input--check:checked + label:before, .input--radio:checked + label:before {
                background: #8039ff;
                border-color: transparent;
            }

/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    margin-bottom: 32px;
}

/* Field label */

.field__lbl {
    display: block;
    padding-bottom: 8px;
    opacity: 0.7;

    font-size: 12px;

    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

/* Field group holds input and buttons together */

.field__group {
    display: table;
}

.field__group > * {
        display: table-cell;
        vertical-align: top;
    }

/* Icon on the left or right of the input */

.field__icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    pointer-events: none;
}

.field__icon--left {
    left: 0;
}

.field__icon--right {
    right: 0;
}

.field--hidden {
    display: none;
}

/*------------------------------------*\
    # component.fieldset
\*------------------------------------*/

.fieldset {
    /**
     * Fieldset base
     */
    padding: 0;
    margin: 0;
    border: 0;
}

.fieldset__legend {
    padding: 32px 0;

    font-size: 14px;

    line-height: 4px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: 700;
}

/*------------------------------------*\
    # component.alert
\*------------------------------------*/

.alert {
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
    border-radius: 4px;
}

.alert--success {
    color: #4caf50;
    background: rgba(76, 175, 80, 0.1);
}

.alert--warning {
    color: #f44336;
    background: rgba(244, 67, 54, 0.1);
}

.alert--note {
    color: #e2ba45;
    background: rgba(226, 186, 69, 0.1);
}

/*------------------------------------*\
    # component.social
\*------------------------------------*/

.social {
}

/*------------------------------------*\
    # component.hamburger
\*------------------------------------*/

.hamburger {
    display: inline-block;
    cursor: pointer;

    -webkit-transition-property: opacity, -webkit-filter;

    transition-property: opacity, -webkit-filter;

    transition-property: opacity, filter;

    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;

    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 10px 12px 4px 12px;
    overflow: visible;
}

.hamburger-box {
    width: 32px;
    height: 30px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
}

.hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
        position: absolute;
        width: 30px;
        height: 2px;

        background-color: black;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 0.15s;
                transition-duration: 0.15s;
        -webkit-transition-timing-function: ease;
                transition-timing-function: ease;
    }

.hamburger-inner:before,
    .hamburger-inner:after {
        content: '';
        display: block;
    }

.hamburger-inner:before {
        top: -10px;
    }

.hamburger-inner:after {
        bottom: -10px;
    }

/* Elastic */

.hamburger--elastic .hamburger-inner {
    top: 4px;
    -webkit-transition-duration: 0.275s;
            transition-duration: 0.275s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner:before {
    top: 8px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner:after {
    top: 16px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 8px, 0) rotate(135deg);
            transform: translate3d(0, 8px, 0) rotate(135deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner:before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner:after {
    -webkit-transform: translate3d(0, -16px, 0) rotate(-270deg);
            transform: translate3d(0, -16px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s;
}

/*------------------------------------*\
    # component.table
\*------------------------------------*/

.table {
    overflow-x: auto;
    overflow-y: hidden;
}

/**
     * Table base
     */

.table thead {
        border-bottom: 1px solid black;
    }

.table th,
    .table td {
        vertical-align: top;
        padding: 8px;
        font-size: 14px;
        line-height: 4px;
    }

.table tr {
        border-bottom: 1px solid black;
    }

.table tr:last-child {
            border-bottom: 1px solid black;
        }

/*------------------------------------*\
    # component.formatted
\*------------------------------------*/

.formatted p,
    .formatted ul,
    .formatted ol,
    .formatted dl,
    .formatted table,
    .formatted object,
    .formatted img {
        margin-bottom: 16px;
    }

.formatted h1,
    .formatted h2,
    .formatted h3,
    .formatted h4,
    .formatted h5,
    .formatted h6 {
        margin: 32px 0;
    }

.formatted ul,
    .formatted ol,
    .formatted dl {
        list-style: inherit;
        list-style-position: inside;
    }

.formatted p:last-child {
            margin-bottom: 0 !important;
        }

/*------------------------------------*\
    # component.list
\*------------------------------------*/

.list {
}

.list__wrapper {
    background: transparent;
}

.list--inline:before {
    content: '';
    display: table;
}

.list--inline:after {
    content: '';
    display: table;
    clear: both;
}

.list--inline > * {
        float: left;
        margin-right: 8px;
    }

.list--bordered > * {
        padding: 8px 0;
        border-bottom: 1px solid rgb(212, 213, 213);
    }

.list--commaseparated > * {
        display: inline;
    }

.list--commaseparated > *:after {
            content: ',';
        }

.list--commaseparated > *:last-child:after {
                content: '';
            }

/*------------------------------------*\
    # component.title
\*------------------------------------*/

.title {
    font-weight: 700;
}

.title--large {
    font-size: 31px;
    line-height: 40px;
}

.title--medium {
    font-size: 20px;
    line-height: 28px;
}

/*------------------------------------*\
    # component.loading
\*------------------------------------*/

.loading {
    display: inline-block;
    position: relative;
    height: 44px;
    width: 44px;
}

.loading div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgb(194, 158, 255);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading div:nth-child(1) {
    left: 6px;
    -webkit-animation: loading1 0.6s infinite;
            animation: loading1 0.6s infinite;
}

.loading div:nth-child(2) {
    left: 6px;
    -webkit-animation: loading2 0.6s infinite;
            animation: loading2 0.6s infinite;
}

.loading div:nth-child(3) {
    left: 26px;
    -webkit-animation: loading2 0.6s infinite;
            animation: loading2 0.6s infinite;
}

.loading div:nth-child(4) {
    left: 45px;
    -webkit-animation: loading3 0.6s infinite;
            animation: loading3 0.6s infinite;
}

.loading--page {
    position: 'absolute';
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@-webkit-keyframes loading1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes loading1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes loading3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@keyframes loading3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@-webkit-keyframes loading2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}

@keyframes loading2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}

/*------------------------------------*\
    # component.pagination
\*------------------------------------*/

.pagination {
    padding: 40px 0;
}

.pagination__wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.pagination__arrow {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 52px;
    height: 52px;
    opacity: 0.5;
}

.pagination__arrow--left {
}

.pagination__arrow--right {
}

.pagination__numbers {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pagination__number {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 52px;
    height: 52px;

    opacity: 0.7;
}

.pagination__number.is-active {
        opacity: 1;
        color: #8039ff;
    }

.pagination__number.is-active:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;

            width: 20%;
            height: 2px;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);

            background: #8039ff;
        }

.pagination__dots {
    opacity: 0.6;
}

/*------------------------------------*\
    # component.proposal-builder-header
\*------------------------------------*/

.proposal-builder-heading {
    position: relative;
    margin-bottom: 32px;
}

/*------------------------------------*\
    # component.proposal-service
\*------------------------------------*/

.proposal-service {
    position: relative;
    background: white;
    margin-bottom: 32px;
}

.proposal-service__dragger {
    position: absolute;
    top: 50%;
    left: -40px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

    width: 20px;
    height: 20px;

    opacity: 0.3;
    cursor: pointer;

    -webkit-transition: all 0.15s ease-out;

    transition: all 0.15s ease-out;
}

.proposal-service__dragger:hover {
        opacity: 0.7;
    }

.proposal-service__body {
    padding: 32px;
}

.proposal-service__body__layout {
    display: flex;
    flex-flow: row wrap;
}

.proposal-service__body__layout__description {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (32px - 32px * 5/12));
    max-width: calc(99.9% * 5/12 - (32px - 32px * 5/12));
    width: calc(99.9% * 5/12 - (32px - 32px * 5/12));
}

.proposal-service__body__layout__description:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.proposal-service__body__layout__description:last-child {
    margin-right: 0;
}

.proposal-service__body__layout__description:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.proposal-service__body__layout__fields {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (32px - 32px * 4/12));
    max-width: calc(99.9% * 4/12 - (32px - 32px * 4/12));
    width: calc(99.9% * 4/12 - (32px - 32px * 4/12));
    display: flex;
    flex-flow: row wrap;
    justify-items: flex-start;
}

.proposal-service__body__layout__fields:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.proposal-service__body__layout__fields:last-child {
    margin-right: 0;
}

.proposal-service__body__layout__fields:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.proposal-service__body__layout__fields .field {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (32px - 32px * 1/2));
    max-width: calc(99.9% * 1/2 - (32px - 32px * 1/2));
    width: calc(99.9% * 1/2 - (32px - 32px * 1/2));
}

.proposal-service__body__layout__fields .field:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.proposal-service__body__layout__fields .field:last-child {
    margin-right: 0;
}

.proposal-service__body__layout__fields .field:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.proposal-service__body__layout__summary {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    max-width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}

.proposal-service__body__layout__summary:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.proposal-service__body__layout__summary:last-child {
    margin-right: 0;
}

.proposal-service__body__layout__summary:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.proposal-service__footer {
    border-top: 1px solid rgba(45, 45, 45, 0.1);
    padding: 20px 0 0;
    margin: 28px 0 0 0;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

/*------------------------------------*\
    # component.proposal-item
\*------------------------------------*/

.proposal-item {
    padding: 32px 32px 16px 32px;
    border-radius: 12px;
    background: white;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
}

.proposal-item + .proposal-item {
        margin-top: 2.5vh;
    }

.proposal-item__info {
    display: flex;
    flex-flow: row wrap;
}

.proposal-item__main {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (32px - 32px * 6/12));
    max-width: calc(99.9% * 6/12 - (32px - 32px * 6/12));
    width: calc(99.9% * 6/12 - (32px - 32px * 6/12));
}

.proposal-item__main:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.proposal-item__main:last-child {
    margin-right: 0;
}

.proposal-item__main:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.proposal-item__title {
    display: block;
    margin-bottom: 32px;
}

.proposal-item__amount {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    max-width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
}

.proposal-item__amount:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.proposal-item__amount:last-child {
    margin-right: 0;
}

.proposal-item__amount:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.proposal-item__client-status {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    max-width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
}

.proposal-item__client-status:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.proposal-item__client-status:last-child {
    margin-right: 0;
}

.proposal-item__client-status:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.proposal-item__year {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.proposal-item__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid rgba(45, 45, 45, 0.1);
}

.proposal-item__actions__item {
}

/*------------------------------------*\
    # component.settings-itemlist
\*------------------------------------*/

.settings-itemlist {
}

.settings-itemlist__header {
    display: grid;
    grid-gap: 24px;
    padding: 0 0 8px 0;

    opacity: 0.4;

    font-size: 12px;

    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

/*
 Since list items in settings have different columns we need to define
 a grid-template differently to match the contents of the grid table
 */

.settings-itemlist__header--services {
    grid-template-columns: minmax(80px, auto) 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__header--units {
    grid-template-columns: 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__header--clients {
    grid-template-columns: 1fr minmax(180px, auto);
}

/* List items */

.settings-itemlist__item {
    display: grid;
    grid-gap: 24px;

    padding: 24px 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.settings-itemlist__item--services {
    grid-template-columns: minmax(80px, auto) 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__item--units {
    grid-template-columns: 1fr 1fr minmax(180px, auto);
}

.settings-itemlist__item--clients {
    grid-template-columns: 1fr minmax(180px, auto);
}

/*------------------------------------*\
    # component.unit-item
\*------------------------------------*/

.unit-item {
    background: rgba(0, 0, 0, 0.02);
    padding: 4px 24px;
}

.unit-item:before {
    content: '';
    display: table;
}

.unit-item:after {
    content: '';
    display: table;
    clear: both;
}

.unit-item:nth-child(odd) {
        background: white;
    }

.unit-item__name {
    float: left;
    text-align: left;
    width: 40%;
}

.unit-item__actions {
    float: right;
    text-align: left;
}

/*------------------------------------*\
    # component.general-info
\*------------------------------------*/

.general-info {
}

.general-info-settings {
    background: #1a191c;
}

.general-info-settings__wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding-top: 24px;
    padding-bottom: 32px;

    margin-bottom: 40px;
}

.general-info-settings__wrapper:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 32px;
        right: 32px;
        height: 1px;
        background: rgba(255, 255, 255, 0.1);
    }

.general-info-settings__wrapper > div {
    min-width: 120px;
    margin-right: 24px;
}

.general-info__fromto {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 40px;
    padding: 40px 0;

    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.general-info__fromto > div {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (32px - 32px * 1/2));
    max-width: calc(99.9% * 1/2 - (32px - 32px * 1/2));
    width: calc(99.9% * 1/2 - (32px - 32px * 1/2));
}

.general-info__fromto > div:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.general-info__fromto > div:last-child {
    margin-right: 0;
}

.general-info__fromto > div:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.general-info__companies {
}

.general-info__currency {
}

.general-info__clientinfo {
}

/*------------------------------------*\
    # component.language-changer
\*------------------------------------*/

.language-changer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.language-changer__item {
}

.language-changer__item--active {
}

/*------------------------------------*\
    # component.service-prices
\*------------------------------------*/

.service-prices {
    text-align: right;
    width: 100%;
}

.service-prices > div {
}

.service-prices p {
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: rgba(51, 51, 51, 0.6);
}

.service-prices__price {
}

.service-prices__discount {
}

.service-prices__total {
}

/*------------------------------------*\
    # component.total-prices
\*------------------------------------*/

.total-prices {
    max-width: 30%;
    margin-left: auto;
}

.total-prices__subtotal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 16px;
}

.total-prices__subtotal--total {
    padding-top: 16px;
    border-top: 1px solid rgba(45, 45, 45, 0.1);
    font-weight: 700;
}

.total-prices__subtotal__label {
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: rgba(51, 51, 51, 0.6);
}

.total-prices__subtotal__value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

/*------------------------------------*\
    # component.photobox
\*------------------------------------*/

.photobox {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    min-height: 320px;
    background-position: center center;
    background-size: cover;
    border: 1px dashed rgba(45, 45, 45, 0.3);
}

.photobox__instructions {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    max-width: 50%;
    text-align: center;
}

.photobox__delete {
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 24px;

    color: white;
}

/*------------------------------------*\
    # component.message
\*------------------------------------*/

.message {
    display: block;
    font-size: 14px;
    line-height: 3px;
}

.message--sml {
    padding: 16px;
}

.message--med {
    padding: 24px;
}

.message--lrg {
    padding: 40px;
}

.message__title {
    margin-bottom: 12px;
}

.message__content {
    margin-bottom: 20px;
}

.message--info {
    background: #8039ff;
    color: white;
    border-radius: 8px;
}

/**
* Import: block
* Description: specific website/app blocks
*/

/*------------------------------------*\
    # block.header
\*------------------------------------*/

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-height: 72px;

    background: #1a191c;
}

.header__branding {
    color: white;
    padding: 24px 0 0 24px;
}

/*------------------------------------*\
    # block.subheader
\*------------------------------------*/

.subheader {
    padding: 5vh 0 2.5vh 0;
    background: #1a191c;
}

.subheader__wrapper {
}

.subheader__split {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.subheader__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    font-size: 31px;

    line-height: 40px;
    color: rgba(255, 255, 255, 0.35);
}

.subheader__links {
    margin-left: -16px;
}

.subheader__links__item {
    display: block;
    padding: 8px 16px;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

.subheader__links__item:hover,
    .subheader__links__item:focus {
        color: rgba(255, 255, 255, 0.8);
    }

.subheader__links__item--active {
    color: rgba(255, 255, 255, 0.8);
    pointer-events: none;
}

.subheader__cta {
    padding-top: 12px;
}

/*------------------------------------*\
    # block.nav
\*------------------------------------*/

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.nav__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.nav__logout {
    color: #f44336;
}

.nav__link {
    display: block;
    color: rgba(255, 255, 255, 0.6);
    padding: 24px;
}

.nav__link:hover,
    .nav__link:focus {
        color: white;
    }

/*------------------------------------*\
    # block.asidenav
\*------------------------------------*/

.asidenav {
}

.asidenav__list {
    margin-bottom: 40px;
}

.asidenav__list__item {
}

.asidenav__list__link {
    position: relative;
    display: block;
    color: rgba(51, 51, 51, 0.6);
    padding: 12px 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.asidenav__list__link:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        background: #8039ff;
        width: 1px;

        opacity: 0;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }

.asidenav__list__link:hover,
    .asidenav__list__link:focus {
        color: #8039ff;
    }

.asidenav__list__link:hover:after, .asidenav__list__link:focus:after {
            opacity: 1;
        }

.asidenav__list__link.active {
        color: #8039ff;
    }

.asidenav__list__link.active:after {
            opacity: 1;
        }

/*------------------------------------*\
    # block.footer
\*------------------------------------*/

.footer {
    padding: 5vh 0;
    margin: 7.5vh 0 0 0;
    border: 1px solid rgba(45, 45, 45, 0.1);
}

.footer__content {}

.footer__content p {
    font-size: 14px;
    line-height: 20px;
    color: rgba(45, 45, 45, 0.5);
}

.footer__content p a {
        display: inline-block;
    }

@media print {

.footer__content p {
        color: black
}

        .footer__content p a {
            color: #1e1ed2;
        }
    }

/*------------------------------------*\
    # block.modal
\*------------------------------------*/

.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow-y: scroll;
    overflow-x: hidden;

    width: 100%;

    pointer-events: none;
    opacity: 0;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;

    background-color: rgba(85, 85, 85, 0.4);
}

@media (min-width: 34em) {

.modal {
        padding: 10% 0
}
    }

.modal.is-visible {
        opacity: 1;
        pointer-events: all;
        -webkit-transform: scale(1);
                transform: scale(1);
    }

.modal__wrapper {
    position: relative;
    z-index: 2;

    margin: 0 auto;

    background-color: white;
    -webkit-box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, .4);
            box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, .4);

    -webkit-transition: all 0.2s;

    transition: all 0.2s;
}

@media (min-width: 34em) {

.modal__wrapper {
        width: 50%;
        min-width: 280px;
        border-radius: 4px
}
    }

.modal__content {
    padding: 32px;
}

.item__title {
    position: relative;
    bottom: 8px;
}

.modal__header {
    padding: 32px;
}

.modal__footer {
    padding: 0 32px 32px 32px;
}

.item__description {
    font-weight: 700;
    position: relative;
    top: 20px;
}

.modal__title {
}

.modal__subtitle {
    color: rgba(255, 255, 255, 0.7);
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
}

.modal__close {
    position: absolute;
    z-index: 2;
    top: 0px;
    right: 0px;

    width: 60px;
    height: 60px;

    color: black;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0;

    -webkit-transition: all 0.2s;

    transition: all 0.2s;
}

.modal__close svg {
        fill: rgba(51, 51, 51, 0.5);
    }

.modal__close:hover svg, .modal__close:focus svg {
            fill: #f44336;
        }

/**
* Import: views
* Description: specific website/app views (example: 404 view, login view)
*/

/* @import 'views.404.css'; */

/*------------------------------------*\
    # views.home
\*------------------------------------*/

.home {
}

/*------------------------------------*\
    # views.login
\*------------------------------------*/

.login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 80vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.login__wrapper {
    width: 100%;
    max-width: 320px;
}

.login__header {
    margin-bottom: 40px;
}

.login__btn {
    width: 100%;
}

/*------------------------------------*\
    # views.offers
\*------------------------------------*/

.offers {
}

.offers__filters {
    margin-top: 48px;
    margin-bottom: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.offers__filters__title {
}

.offers__filters__status button,
.offers__filters__order button,
.offers__filters__year button {
    margin-right: 8px;
}

/*------------------------------------*\
    # views.services
\*------------------------------------*/

.services {
}

.services__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/*------------------------------------*\
    # views.units
\*------------------------------------*/

.units {
}

.units__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/*------------------------------------*\
    # views.settings
\*------------------------------------*/

.settings {
}

.settings__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(45, 45, 45, 0.2);
}

.settings__header__content {
    padding-right: 32px;
    max-width: 500px;
}

.settings__header__action {
}

.settings__layout {
    display: flex;
    flex-flow: row wrap;
    padding-top: 40px;
}

.settings__layout__aside {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    max-width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    width: calc(99.9% * 3/12 - (32px - 32px * 3/12));
    border-right: 1px solid rgba(45, 45, 45, 0.1);
}

.settings__layout__aside:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.settings__layout__aside:last-child {
    margin-right: 0;
}

.settings__layout__aside:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.settings__layout__content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (32px - 32px * 8/12));
    max-width: calc(99.9% * 8/12 - (32px - 32px * 8/12));
    width: calc(99.9% * 8/12 - (32px - 32px * 8/12));
    position: relative;
    left: calc(99.9% * 1/12 - (32px - 32px * 1/12) + 32px);
}

.settings__layout__content:nth-child(1n) {
    margin-right: 32px;
    margin-left: 0;
}

.settings__layout__content:last-child {
    margin-right: 0;
}

.settings__layout__content:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

/*------------------------------------*\
    # views.proposal-create
\*------------------------------------*/

.proposal-create {
}

.proposal-create__heading {
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    cursor: pointer;
}

.proposal-create__heading:hover,
    .proposal-create__heading:focus {
        background: rgba(0, 0, 0, 0.1);
    }

.proposal-create__subheading {
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    cursor: pointer;
}

.proposal-create__subheading:hover,
    .proposal-create__subheading:focus {
        background: rgba(0, 0, 0, 0.1);
    }

.proposal-create__add-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 40px 0;
}

.proposal-create__save-block {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px;
    background: #1a191c;
}

.proposal-create__saved {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #4caf50;
    padding: 8px 24px;
    border-radius: 1000px;
    color: white;
}

/*------------------------------------*\
    # views.errorpage
\*------------------------------------*/

.errorpage {
}

.errorpage__wrapper {
    max-width: 600px;
}

.errorpage__header {
    padding: 80px 0;
}

.errorpage__content {
    padding-bottom: 40px;
}

.errorpage__action {
}

/**
* View: Proposal
* Description: a public or preview view of the created proposal
*/

/*------------------------------------*\
    # views.proposal
\*------------------------------------*/

.proposal {
}

.proposal__header {
    position: relative;
    z-index: 1;
    padding: 10vh 0 10vh 0;

    background-size: cover;
    background-position: center center;
    background-color: #2d2d2d;
}

.proposal__header:after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        background: -webkit-gradient(
            linear,
            left bottom, left top,
            from(rgba(26, 25, 28, 0.6)),
            to(rgba(26, 25, 28, 0.4))
        );
        background: linear-gradient(
            to top,
            rgba(26, 25, 28, 0.6) 0%,
            rgba(26, 25, 28, 0.4) 100%
        );

        width: 100%;
        height: 100%;
    }

.proposal__header__heading {
    margin-bottom: 120px;
}

@media print {
        .proposal__header__heading p,
        .proposal__header__heading h1 {
            color: black;
        }
    }

.proposal__details {
    padding-top: 5vh;
}

.proposal__to {
    position: relative;
    z-index: 1;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    color: white;
    opacity: 0.6;
}

@media print {

.proposal__to {
        color: black;
        opacity: 1
}
    }

.proposal__to__client {
    max-width: 300px;
}

.proposal__total {
    padding-top: 20px;
}

@media (min-width: 34em) {

.proposal__total {
        padding-top: 40px
}
    }

@media (min-width: 62em) {

.proposal__total {
        display: flex;
        flex-flow: row wrap
}
    }

@media (min-width: 62em) {

.proposal__total__list {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (32px - 32px * 6/12));
        max-width: calc(99.9% * 6/12 - (32px - 32px * 6/12));
        width: calc(99.9% * 6/12 - (32px - 32px * 6/12));
        position: relative;
        left: calc(99.9% * 6/12 - (32px - 32px * 6/12) + 32px)
}

.proposal__total__list:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}

.proposal__total__list:last-child {
        margin-right: 0
}

.proposal__total__list:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.proposal__total__list__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 4px 0;
}

.proposal__total__list__item--total {
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    padding-top: 16px;
    margin-top: 20px;
}

.proposal__total__list__item--total .proposal__total__list__label {
    padding-top: 8px;
}

.proposal__total__list__label {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.proposal__total__list__value {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: right;
}

.proposal__footer {
    padding-top: 20px;
    margin-top: 40px;
}

@media (min-width: 34em) {

.proposal__footer {
        display: flex;
        flex-flow: row wrap;

        border-top: 1px solid rgba(45, 45, 45, 0.2);
        padding-top: 40px;
        margin-top: 120px
}
    }

.proposal__footer__companyinfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.proposal__footer__companyinfo__branding {
    max-width: 72px;
    margin-right: 32px;
}

.proposal__footer__companyinfo__info {
    max-width: 300px;
}

/*------------------------------------*\
    # block.proposal-block-heading
\*------------------------------------*/

.proposal-block-heading {
    position: relative;
    padding-top: 16px;
    padding-bottom: 16px;
}

@media (min-width: 34em) {

.proposal-block-heading {
        display: flex;
        flex-flow: row wrap;
        padding-top: 32px;
        padding-bottom: 32px
}
    }

/* Special case when a text block is directly below the proposal heading */

.proposal-block-heading + .proposal-block-text {
        margin-top: 0;
    }

@media (max-width: 33.9375em) {

.proposal-block-heading__heading {
        margin-bottom: 16px
}
    }

@media (min-width: 34em) {

.proposal-block-heading__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (32px - 32px * 5/12));
        max-width: calc(99.9% * 5/12 - (32px - 32px * 5/12));
        width: calc(99.9% * 5/12 - (32px - 32px * 5/12))
}

.proposal-block-heading__heading:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}

.proposal-block-heading__heading:last-child {
        margin-right: 0
}

.proposal-block-heading__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media (min-width: 34em) {

.proposal-block-heading__description {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (32px - 32px * 6/12));
        max-width: calc(99.9% * 6/12 - (32px - 32px * 6/12));
        width: calc(99.9% * 6/12 - (32px - 32px * 6/12));
        position: relative;
        left: calc(99.9% * 1/12 - (32px - 32px * 1/12) + 32px)
}

.proposal-block-heading__description:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}

.proposal-block-heading__description:last-child {
        margin-right: 0
}

.proposal-block-heading__description:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.proposal-block-service
\*------------------------------------*/

.proposal-block-service {
    padding: 24px;
    background: white;
    margin-bottom: 2.5vh;
}

@media (max-width: 33.9375em) {

.proposal-block-service {
        margin-right: -32px;
        margin-left: -32px
}
    }

@media (min-width: 34em) {

.proposal-block-service {
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);

        border-radius: 12px
}
    }

@media (min-width: 62em) {

.proposal-block-service {
        padding: 0 40px;
        display: flex;
        flex-flow: row wrap
}
    }

.proposal-block-service__name {
    margin-bottom: 20px;
}

@media (max-width: 61.9375em) {

.proposal-block-service__info {
        margin-bottom: 32px
}
    }

@media (min-width: 62em) {

.proposal-block-service__info {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (32px - 32px * 5/12));
        max-width: calc(99.9% * 5/12 - (32px - 32px * 5/12));
        width: calc(99.9% * 5/12 - (32px - 32px * 5/12));
        padding: 40px 32px 40px 0;
        border-right: 1px solid rgba(45, 45, 45, 0.1)
}

.proposal-block-service__info:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}

.proposal-block-service__info:last-child {
        margin-right: 0
}

.proposal-block-service__info:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media (min-width: 62em) {

.proposal-block-service__pricing {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (32px - 32px * 7/12));
        max-width: calc(99.9% * 7/12 - (32px - 32px * 7/12));
        width: calc(99.9% * 7/12 - (32px - 32px * 7/12));
        padding: 40px 0
}

.proposal-block-service__pricing:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}

.proposal-block-service__pricing:last-child {
        margin-right: 0
}

.proposal-block-service__pricing:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.proposal-block-service__pricing__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(45, 45, 45, 0.1);
}

.proposal-block-service__pricing__top__quantity {
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2;
}

.proposal-block-service__pricing__top__price {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.proposal-block-service__pricing__top__totalvalue {
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2;
    text-align: right;
}

.proposal-block-service__pricing__discount {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(45, 45, 45, 0.1);
}

.proposal-block-service__pricing__discount > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.proposal-block-service__pricing__subtotal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 16px;
}

.proposal-block-service__pricing__subtotal > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.proposal-block-service__title {
}

.proposal-block-service__description {
}

/*------------------------------------*\
    # block.proposal-block-text
\*------------------------------------*/

.proposal-block-text {
    position: relative;
    margin: 40px 0;
    padding-left: 24px;
}

.proposal-block-text:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: rgba(45, 45, 45, 0.2);
    }

/**
* Import: utils
* Description: reusable utilities such as floats, spacers etc.
*/

/*------------------------------------*\
    # utils.grid
\*------------------------------------*/

/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.u-width--full {
    width: 100%;
}

/*------------------------------------*\
    # utils.group
\*------------------------------------*/

.u-group:after {
        content: '';
        display: block;
        clear: both;
    }

/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/

.s- {
}

/* Top */

.s-top {
}

.s-top--tny {
    margin-top: 4px;
}

.s-top--sml {
    margin-top: 8px;
}

.s-top--med {
    margin-top: 16px;
}

.s-top--lrg {
    margin-top: 32px;
}

/* Right */

.s-right {
}

.s-right--tny {
    margin-right: 4px;
}

.s-right--sml {
    margin-right: 8px;
}

.s-right--med {
    margin-right: 16px;
}

.s-right--lrg {
    margin-right: 32px;
}

/* Bottom */

.s-bottom {
}

.s-bottom--tny {
    margin-bottom: 4px;
}

.s-bottom--sml {
    margin-bottom: 8px;
}

.s-bottom--med {
    margin-bottom: 16px;
}

.s-bottom--lrg {
    margin-bottom: 32px;
}

/* Left */

.s-left {
}

.s-left--tny {
    margin-left: 4px;
}

.s-left--sml {
    margin-left: 8px;
}

.s-left--med {
    margin-left: 16px;
}

.s-left--lrg {
    margin-left: 32px;
}

/*------------------------------------*\
    # utils.type
\*------------------------------------*/

/* Alignment */

.t-left {
    text-align: left;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

/* Weight */

.t-light {
    font-weight: 300;
}

/* Case */

.t-upper {
    text-transform: uppercase;
}

.t-upperext {
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

/* Sizes */

.t-alpha {
    font-size: 61px;
    line-height: 80px;
}

.t-beta {
    font-size: calc(25.85714px + 1.60714vw); /* $type-xlarge, $type-xxxlarge */ /* viewport widths between which font-size is fluid */

    line-height: calc(31.42857px + 1.42857vw);
}

@media screen and (min-width: 1440px) {

    .t-beta {
        line-height: 52px;
    }
}

@media screen and (max-width: 320px) {

    .t-beta {
        line-height: 36px;
    }
}

@media screen and (min-width: 1440px) {

    .t-beta {
        font-size: 49px;
    }
}

@media screen and (max-width: 320px) {

    .t-beta {
        font-size: 31px;
    }
}

.t-gamma {
    font-size: calc(21px + 1.25vw); /* $type-large, $type-xxlarge */ /* viewport widths between which font-size is fluid */

    line-height: calc(28.57143px + 1.07143vw);
}

@media screen and (min-width: 1440px) {

    .t-gamma {
        line-height: 44px;
    }
}

@media screen and (max-width: 320px) {

    .t-gamma {
        line-height: 32px;
    }
}

@media screen and (min-width: 1440px) {

    .t-gamma {
        font-size: 39px;
    }
}

@media screen and (max-width: 320px) {

    .t-gamma {
        font-size: 25px;
    }
}

.t-delta {
    font-size: calc(16.85714px + 0.98214vw); /* $type-medium, $type-xlarge */ /* viewport widths between which font-size is fluid */

    line-height: calc(20.57143px + 1.07143vw);
}

@media screen and (min-width: 1440px) {

    .t-delta {
        line-height: 36px;
    }
}

@media screen and (max-width: 320px) {

    .t-delta {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .t-delta {
        font-size: 31px;
    }
}

@media screen and (max-width: 320px) {

    .t-delta {
        font-size: 20px;
    }
}

.t-epsilon {
    font-size: calc(13.42857px + 0.80357vw); /* $type-base, $type-large */ /* viewport widths between which font-size is fluid */

    line-height: calc(21.71429px + 0.71429vw);
}

@media screen and (min-width: 1440px) {

    .t-epsilon {
        line-height: 32px;
    }
}

@media screen and (max-width: 320px) {

    .t-epsilon {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .t-epsilon {
        font-size: 25px;
    }
}

@media screen and (max-width: 320px) {

    .t-epsilon {
        font-size: 16px;
    }
}

.t-zeta {
    font-size: calc(14.85714px + 0.35714vw); /* $type-base, $type-medium */ /* viewport widths between which font-size is fluid */

    line-height: calc(22.85714px + 0.35714vw);
}

@media screen and (min-width: 1440px) {

    .t-zeta {
        line-height: 28px;
    }
}

@media screen and (max-width: 320px) {

    .t-zeta {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .t-zeta {
        font-size: 20px;
    }
}

@media screen and (max-width: 320px) {

    .t-zeta {
        font-size: 16px;
    }
}

.t-eta {
    font-size: calc(13.42857px + 0.17857vw); /* $type-small, $type-base */ /* viewport widths between which font-size is fluid */

    line-height: calc(18.85714px + 0.35714vw);
}

@media screen and (min-width: 1440px) {

    .t-eta {
        line-height: 24px;
    }
}

@media screen and (max-width: 320px) {

    .t-eta {
        line-height: 20px;
    }
}

@media screen and (min-width: 1440px) {

    .t-eta {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {

    .t-eta {
        font-size: 14px;
    }
}

.t-small {
    font-size: 14px;
    line-height: 20px;
}

.t-tiny {
    font-size: 13px;
    line-height: 16px;
}

.t-micro {
    font-size: 12px;
    line-height: 16px;
}

/* Colors */

.t-primary {
    color: #8039ff;
}

.t-secondary {
    color: #555;
}

.t-negative {
    color: white;
}

.t-faded {
    color: rgba(51, 51, 51, 0.5);
}

/* Shorten long links */

.t-shorten {
    /* These are technically the same, but use both */
    word-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.t-wrap {
    word-wrap: break-word;
    word-wrap: break-word;
}

/*------------------------------------*\
    # utils.float
\*------------------------------------*/

.u-float {
}

.u-float--left {
    float: left;
}

.u-float--right {
    float: right;
}

/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-pos {
}

.u-pos--rel {
    position: relative;
}

.u-pos--abs {
    position: absolute;
}

/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.u-bg {
}

.u-bg--primary {
    background-color: #8039ff;
}

/*------------------------------------*\
    # utils.color
\*------------------------------------*/

.u-color {
}

.u-color--success {
    color: #4caf50;
}

.u-color--warning {
    color: #f44336;
}

.u-color--note {
    color: #e2ba45;
}

.u-color--bg {
    background-color: #f8f8f8;
}

/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/

.o {
}

.o-100 {
    opacity: 1;
}

.o-80 {
    opacity: 0.8;
}

.o-60 {
    opacity: 0.6;
}

.o-40 {
    opacity: 0.4;
}

.o-20 {
    opacity: 0.2;
}

/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.d {
}

.d--b {
    display: block;
}

.d--ib {
    display: inline-block;
}

.d--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.d--none {
    display: none;
}

/*------------------------------------*\
    # utils.visibility
\*------------------------------------*/

.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

/*------------------------------------*\
    # utils.flex
\*------------------------------------*/

.f {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.f--between-center {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

/**
* Import: plugins
* Description: 3rd party code, external plugin CSS etc
* Note: when importing code from a 3rd party it should be stripped off
* any vendor prefixes since autoprefixer will use project specific vendor prefixes
*/

/*------------------------------------*\
    # plugins.fontface
\*------------------------------------*/

@font-face {
    font-family: 'Aeonik';
    src: url(/static/media/Aeonik-Bold.06523ced.eot?#iefix);
    src: url(/static/media/Aeonik-Bold.06523ced.eot?#iefix) format('eot'),
        url(/static/media/Aeonik-Bold.07255666.woff2) format('woff2'),
        url(/static/media/Aeonik-Bold.ccac5264.woff) format('woff'),
        url(/static/media/Aeonik-Bold.cacb4809.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Aeonik';
    src: url(/static/media/Aeonik-BoldItalic.79c87750.eot?#iefix);
    src: url(/static/media/Aeonik-BoldItalic.79c87750.eot?#iefix)
            format('eot'),
        url(/static/media/Aeonik-BoldItalic.3c206f24.woff2)
            format('woff2'),
        url(/static/media/Aeonik-BoldItalic.f3fecfb8.woff)
            format('woff'),
        url(/static/media/Aeonik-BoldItalic.b0139977.ttf)
            format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(/static/media/Aeonik-RegularItalic.17284a7f.eot?#iefix);
    src: url(/static/media/Aeonik-RegularItalic.17284a7f.eot?#iefix)
            format('eot'),
        url(/static/media/Aeonik-RegularItalic.4fd7708a.woff2)
            format('woff2'),
        url(/static/media/Aeonik-RegularItalic.1f65deec.woff)
            format('woff'),
        url(/static/media/Aeonik-RegularItalic.c80f44ed.ttf)
            format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(/static/media/Aeonik-Regular.f841f92e.eot?#iefix);
    src: url(/static/media/Aeonik-Regular.f841f92e.eot?#iefix)
            format('eot'),
        url(/static/media/Aeonik-Regular.a0dd3136.woff2)
            format('woff2'),
        url(/static/media/Aeonik-Regular.62e26876.woff) format('woff'),
        url(/static/media/Aeonik-Regular.51f183cc.ttf)
            format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Aeonik';
    src: url(/static/media/Aeonik-LightItalic.d3fe648c.eot?#iefix);
    src: url(/static/media/Aeonik-LightItalic.d3fe648c.eot?#iefix)
            format('eot'),
        url(/static/media/Aeonik-LightItalic.eb8d9e3b.woff2)
            format('woff2'),
        url(/static/media/Aeonik-LightItalic.a56941e6.woff)
            format('woff'),
        url(/static/media/Aeonik-LightItalic.ebf3a814.ttf)
            format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(/static/media/Aeonik-Light.bc890396.eot?#iefix);
    src: url(/static/media/Aeonik-Light.bc890396.eot?#iefix)
            format('eot'),
        url(/static/media/Aeonik-Light.9746e2cb.woff2) format('woff2'),
        url(/static/media/Aeonik-Light.8afa2dc8.woff) format('woff'),
        url(/static/media/Aeonik-Light.0d6ed129.ttf) format('truetype');
    font-weight: 300;
}

/*------------------------------------*\
    # plugins.dropzone
\*------------------------------------*/

.dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
}

.dropzone p {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
}

.dropzone img {
    height: 100%;
    width: auto;
}

/*------------------------------------*\
    # plugins.flatpickr
\*------------------------------------*/

@-webkit-keyframes fpFadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes fpFadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

.flatpickr-calendar {
    position: absolute;
    padding: 0;

    width: 300px;

    opacity: 0;
    display: none;
    visibility: hidden;
    -webkit-animation: none;
            animation: none;
    direction: ltr;

    text-align: center;
    border: 0;
    border-radius: 5px;

    -ms-touch-action: manipulation;

        touch-action: manipulation;

    background: white;
}

.flatpickr-disabled {
    color: rgba(45, 45, 45, .3);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
    max-height: 640px;
    opacity: 1;
    visibility: visible;
}

.flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
}

.flatpickr-calendar.multiMonth
    .flatpickr-days
    .dayContainer:nth-child(n + 1)
    .flatpickr-day.inRange:nth-child(7n + 7) {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.flatpickr-calendar.multiMonth
    .flatpickr-days
    .dayContainer:nth-child(n + 2)
    .flatpickr-day.inRange:nth-child(7n + 1) {
    -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
            box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
    left: auto;
    right: 22px;
}

.flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px;
}

.flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
    bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: white;
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: white;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
    top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
    border-top-color: #20222c;
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #3f4458;
}

.flatpickr-calendar:focus {
    outline: 0;
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block;
}

.flatpickr-months {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    border-bottom: 1px solid rgba(45, 45, 45, 0.05);
}

.flatpickr-months .flatpickr-month {
    height: 40px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
    text-align: center;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    height: 40px;
    width: 40px;

    padding-top: 11px;
    text-decoration: none;
    cursor: pointer;
}

.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
    display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
    position: relative;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
    background: rgba(45, 45, 45, 0.07);
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: #8039ff;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
    fill: rgba(45, 45, 45, 0.5);
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
    -webkit-transition: fill 0.1s;
    transition: fill 0.1s;
    fill: inherit;
}

.flatpickr-months .flatpickr-prev-month {
    border-right: 1px solid rgba(45, 45, 45, 0.05);
}

.flatpickr-months .flatpickr-next-month {
    border-left: 1px solid rgba(45, 45, 45, 0.05);
}

.numInputWrapper {
    position: relative;
}

.numInputWrapper input,
.numInputWrapper span {
}

.numInputWrapper input {
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
            appearance: none;
}

.numInputWrapper span {
    position: absolute;
    right: 0;

    width: 16px;
    height: 50%;
    padding: 0 4px 0 2px;

    opacity: 0;
    cursor: pointer;
}

.numInputWrapper span:hover {
    background: rgba(45, 45, 45, 0.1);
}

.numInputWrapper span:active {
}

.numInputWrapper span:after {
    position: absolute;
    display: block;
    content: '';
}

.numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
    top: 8px;
    left: 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
}

.numInputWrapper span.arrowDown {
    top: 50%;
}

.numInputWrapper span.arrowDown:after {
    top: 8px;
    left: 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid transparent;
}

.numInputWrapper span svg {
    width: inherit;
    height: auto;
}

.numInputWrapper span svg path {
    fill: rgba(45, 45, 45, 0.5);
}

.numInputWrapper:hover {
}

.numInputWrapper:hover span {
    opacity: 1;
}

.flatpickr-current-month {
    position: absolute;
    width: 75%;
    left: 12.5%;
    height: 40px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.flatpickr-current-month span.cur-month {
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    padding: 0 8px;
}

.flatpickr-current-month span.cur-month:hover {
}

.flatpickr-current-month .numInputWrapper {
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: #8039ff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: #8039ff;
}

.flatpickr-current-month input.cur-year {
    background: transparent;
    cursor: text;
    font-weight: 300;
    height: 40px;
    border: 0;
    border-radius: 0;
    vertical-align: baseline;
    vertical-align: initial;
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
    outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    background: transparent;
    pointer-events: none;
}

.flatpickr-weekdays {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;

    font-size: 12px;

    line-height: 16px;
    color: rgba(51, 51, 51, 0.5);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    height: 40px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: transparent;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    font-weight: 700;
}

.dayContainer,
.flatpickr-weeks {
    padding: 1px 0 0 0;
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 300px;
}

.flatpickr-days:focus {
    outline: 0;
}

.dayContainer {
    text-align: left;
    width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}

.flatpickr-day {
    border-radius: 150px;
    cursor: pointer;
    height: 38px;
    -ms-flex-preferred-size: 38px;
        flex-basis: 38px;
    line-height: 38px;
    text-align: center;

    margin: 2px;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    background: rgba(128, 57, 255, 0.1);
}

.flatpickr-day.today {
    font-weight: 700;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    background: #2d2d2d;
    color: white;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #8039ff;
    font-weight: 700;
    color: white;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 #80cbc4;
            box-shadow: -10px 0 0 #80cbc4;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
}

.flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
            box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
    color: rgba(45, 45, 45, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
    cursor: not-allowed;
    color: rgba(255, 255, 255, .1);
}

.flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
            box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
}

.flatpickr-day.hidden {
    visibility: hidden;
}

.rangeMode .flatpickr-day {
    margin-top: 1px;
}

.flatpickr-weekwrapper {
    display: inline-block;
    float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #20222c;
            box-shadow: 1px 0 0 #20222c;
}

.flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(45, 45, 45, 0.3);
    background: transparent;
    cursor: default;
    border: none;
}

.flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
}

.flatpickr-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid rgba(45, 45, 45, 0.05);
}

.flatpickr-time .numInputWrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 40%;
    height: 40px;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #8039ff;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #8039ff;
}

.flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
}

.flatpickr-time input {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    height: 40px;
    width: 100%;
    background: transparent;
    border: 0;
    text-align: center;
    position: relative;
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
}

.flatpickr-time input:focus {
    outline: 0;
    border: 0;
}

.flatpickr-time .flatpickr-time-separator {
    opacity: 0.3;
    width: 2%;
    -ms-flex-item-align: center;
        align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
    height: 40px;
    width: 18%;

    font-size: 14px;

    line-height: 16px;
    line-height: 40px;

    cursor: pointer;
    text-align: center;

    border-left: 1px solid rgba(45, 45, 45, 0.05);
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
    background: rgba(45, 45, 45, 0.07);
}

.flatpickr-input[readonly] {
    cursor: pointer;
}

/*------------------------------------*\
    # plugins.slate
\*------------------------------------*/

.slate {}

.slate__toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.slate__toolbar__icon {
    display: block;
    padding: 8px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    opacity: 0.5;
}

.slate__toolbar__icon:hover,
    .slate__toolbar__icon:focus {
        opacity: 1;
    }

.slate__editor {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid rgba(45, 45, 45, 0.15);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
}

.slate__editor:hover,
    .slate__editor:focus {
        background: rgba(45, 45, 45, 0.05);
    }

.slate__editor--height {
    min-height: 160px;
}

.slate .is-readonly {
    padding: 0;
    border: 0;

    -webkit-transition: none;

    transition: none;
}

.slate .is-readonly:hover,
    .slate .is-readonly:focus {
        background: transparent;
    }

.slate .is-readonly li {
        padding-left: 24px;
        text-indent: -20px;
    }

/*------------------------------------*\
    # plugins.toastify
\*------------------------------------*/

.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 320px;
    color: white;
}

.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em;
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px;
}

.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em;
}

.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em;
}

.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px;
}

.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0;
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 0;
    }

    .Toastify__toast-container--bottom-left,
    .Toastify__toast-container--bottom-center,
    .Toastify__toast-container--bottom-right {
        bottom: 0;
    }

    .Toastify__toast-container--rtl {
        right: 0;
        left: auto;
        left: initial;
    }
}

.Toastify__toast {
    position: relative;
    min-height: 48px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px 24px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1),
        0 2px 15px 0 rgba(0, 0, 0, .05);
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1),
        0 2px 15px 0 rgba(0, 0, 0, .05);
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
            justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    cursor: pointer;
    direction: ltr;

    font-size: 14px;

    line-height: 16px;
}

.Toastify__toast--rtl {
    direction: rtl;
}

.Toastify__toast--default {
    background: white;
    color: #aaa;
}

.Toastify__toast--info {
    background: #8039ff;
}

.Toastify__toast--success {
    background: #4caf50;
}

.Toastify__toast--warning {
    background: #f44336;
}

.Toastify__toast--error {
    background: #e2ba45;
}

.Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1;
    -webkit-box-flex: 1;
            flex: 1;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        margin-bottom: 0;
    }
}

.Toastify__close-button {
    color: white;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.Toastify__close-button--default {
    color: #000;
    opacity: 0.3;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
    0% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }

    100% {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }
}

@keyframes Toastify__trackProgress {
    0% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }

    100% {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }
}

.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 9999;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, .7);
    -webkit-transform-origin: left;
            transform-origin: left;
}

.Toastify__progress-bar--animated {
    -webkit-animation: Toastify__trackProgress linear 1 forwards;
            animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.Toastify__progress-bar--rtl {
    right: 0;
    left: auto;
    left: initial;
    -webkit-transform-origin: right;
            transform-origin: right;
}

.Toastify__progress-bar--default {
    background-color: rgba(0, 0, 0, .7);
}

@-webkit-keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
                transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }
}

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
                transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }
}

@-webkit-keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
                transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
                transform: translate3d(25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
                transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
                transform: translate3d(25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}

@-webkit-keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
                transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
                transform: translate3d(0, -5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
                transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
                transform: translate3d(0, -5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }
}

@keyframes Toastify__bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }
}

@-webkit-keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
                transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
                transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
                transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
                transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
                transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
                transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes Toastify__bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }
}

@keyframes Toastify__bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }
}

.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
    -webkit-animation-name: Toastify__bounceInLeft;
            animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
    -webkit-animation-name: Toastify__bounceInRight;
            animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
    -webkit-animation-name: Toastify__bounceInDown;
            animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
    -webkit-animation-name: Toastify__bounceInUp;
            animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
    -webkit-animation-name: Toastify__bounceOutLeft;
            animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    -webkit-animation-name: Toastify__bounceOutRight;
            animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
    -webkit-animation-name: Toastify__bounceOutUp;
            animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
    -webkit-animation-name: Toastify__bounceOutDown;
            animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}

@keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes Toastify__zoomOut {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}

@keyframes Toastify__zoomOut {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}

.Toastify__zoom-enter {
    -webkit-animation-name: Toastify__zoomIn;
            animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
    -webkit-animation-name: Toastify__zoomOut;
            animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
}

@keyframes Toastify__flipIn {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
}

@-webkit-keyframes Toastify__flipOut {
    from {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@keyframes Toastify__flipOut {
    from {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

.Toastify__flip-enter {
    -webkit-animation-name: Toastify__flipIn;
            animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
    -webkit-animation-name: Toastify__flipOut;
            animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
    from {
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInRight {
    from {
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideInLeft {
    from {
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInLeft {
    from {
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideInUp {
    from {
        -webkit-transform: translate3d(0, 110%, 0);
                transform: translate3d(0, 110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInUp {
    from {
        -webkit-transform: translate3d(0, 110%, 0);
                transform: translate3d(0, 110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideInDown {
    from {
        -webkit-transform: translate3d(0, -110%, 0);
                transform: translate3d(0, -110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInDown {
    from {
        -webkit-transform: translate3d(0, -110%, 0);
                transform: translate3d(0, -110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
    }
}

@keyframes Toastify__slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
    }
}

@keyframes Toastify__slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 500px, 0);
                transform: translate3d(0, 500px, 0);
    }
}

@keyframes Toastify__slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 500px, 0);
                transform: translate3d(0, 500px, 0);
    }
}

@-webkit-keyframes Toastify__slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -500px, 0);
                transform: translate3d(0, -500px, 0);
    }
}

@keyframes Toastify__slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -500px, 0);
                transform: translate3d(0, -500px, 0);
    }
}

.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
    -webkit-animation-name: Toastify__slideInLeft;
            animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
    -webkit-animation-name: Toastify__slideInRight;
            animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
    -webkit-animation-name: Toastify__slideInDown;
            animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
    -webkit-animation-name: Toastify__slideInUp;
            animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
    -webkit-animation-name: Toastify__slideOutLeft;
            animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
    -webkit-animation-name: Toastify__slideOutRight;
            animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
    -webkit-animation-name: Toastify__slideOutUp;
            animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
    -webkit-animation-name: Toastify__slideOutDown;
            animation-name: Toastify__slideOutDown;
}

/**
* Import: shame
* Description: CSS shame file
* Note: to be avoided, exists only if REALLY necessary or legacy code
*/

/* @import 'shame.css'; */

