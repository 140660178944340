/*------------------------------------*\
    # plugins.slate
\*------------------------------------*/

.slate {}

.slate__toolbar {
    display: flex;
}

.slate__toolbar__icon {
    display: block;
    padding: calc($unit * 2);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    opacity: 0.5;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.slate__editor {
    padding: calc($unit * 4);
    border-radius: $global-radial;
    border: 1px solid color($color-dark a(0.15));
    transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
        background: color($color-dark a(0.05));
    }
}

.slate__editor--height {
    min-height: 160px;
}

.slate .is-readonly {
    padding: 0;
    border: 0;

    transition: none;

    &:hover,
    &:focus {
        background: transparent;
    }

    li {
        padding-left: calc($unit * 6);
        text-indent: calc($unit * -5);
    }
}