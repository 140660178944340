/*------------------------------------*\
    # block.proposal-block-heading
\*------------------------------------*/

.proposal-block-heading {
    position: relative;
    padding-top: calc($unit * 4);
    padding-bottom: calc($unit * 4);

    @media (--screen-from-sml) {
        lost-flex-container: row;
        padding-top: calc($unit * 8);
        padding-bottom: calc($unit * 8);
    }

    /* Special case when a text block is directly below the proposal heading */
    & + .proposal-block-text {
        margin-top: 0;
    }
}

.proposal-block-heading__heading {
    @media (--screen-to-sml) {
        margin-bottom: calc($unit * 4);
    }
    @media (--screen-from-sml) {
        lost-column: 5/12;
    }
}

.proposal-block-heading__description {
    @media (--screen-from-sml) {
        lost-column: 6/12;
        lost-move: 1/12;
    }
}
