/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    display: inline-flex;
    align-items: center;
    justify-content: center;

    touch-action: manipulation;
    user-select: none;

    /**
     * Remove border radius on iOS buttons
     */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-radius: $global-radial;
    font-family: $ff-sans;

    transition: all 0.15s ease-out;

    &:focus {
        outline: 0;
    }

    /**
     * Disabled
     */

    &.is-disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.btn--core {
    text-transform: initial;
    letter-spacing: initial;
    border: 0;
    border-radius: 0;
    padding: 0;
    font-family: $ff-sans;
}

.btn--text {
    color: $color-primary;

    &:hover,
    &:focus {
        color: color($color-primary lightness(+30%));
    }
}

.btn--basic {
    color: color($color-text lightness(+30%));

    &:hover,
    &:focus {
        color: color($color-text lightness(+50%));
    }
}

.btn--filter {
    color: color($color-text a(0.6));
    @mixin type-scale $type-small, 4;
}

.btn--filter--active {
    color: $color-primary;
    font-weight: $type-weight-bold;
}

/**
 * Button styles
 */

.btn--primary {
    position: relative;
    border: 1px solid color($color-primary a(0.2));
    color: white;
    background-color: $color-primary;
    box-shadow: 0 2px 10px color($color-primary a(0.3));

    &:hover,
    &:focus {
        background-color: color($color-primary blackness(+20%));
        color: white;
        box-shadow: 0 2px 15px color($color-primary a(0.5));
    }
}

.btn--dark {
    position: relative;
    color: white;
    background-color: color($color-dark blackness(+20%));
    box-shadow: 0 2px 10px color($color-dark a(0.3));

    &:hover,
    &:focus {
        color: white;
        box-shadow: 0 2px 15px color($color-dark a(0.5));
    }
}

.btn--negative {
    position: relative;
    color: $color-text;
    background-color: white;
    box-shadow: 0 2px 10px color($color-dark a(0.1));

    &:hover,
    &:focus {
        background-color: color(white a(0.8));
        color: $color-text;
        box-shadow: 0 2px 15px color($color-dark a(0.2));
    }
}

.btn--ghost {
    position: relative;
    color: $color-text;
    background-color: transparent;

    &:hover,
    &:focus {
        background-color: color($color-dark a(0.05));
        color: $color-text;
    }
}

/**
 * Spacer - a placeholder for text between
 * two or more buttons
 */

.btn-spacer {
    padding: 0 calc($unit);
    @mixin type-scale $type-tiny, 4;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: color(white blackness(+50%));
}

/**
 * Button sizes
 */

.btn--tny {
    @mixin type-scale $type-tiny, 4;
    height: calc($unit * 8);
    padding: 0 calc($unit * 4);

    &--iconOnly {
        padding: 0 calc($unit * 2);
    }
}

.btn--sml {
    @mixin type-scale $type-tiny, 4;
    height: calc($unit * 10);
    padding: 0 calc($unit * 6);

    &--iconOnly {
        padding: 0 calc($unit * 3);
    }
}

.btn--med {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 11);
    padding: 0 calc($unit * 8);

    &--iconOnly {
        padding: 0 calc($unit * 4);
    }
}

.btn--lrg {
    @mixin type-scale $type-base, 6;
    height: calc($unit * 11);
    padding: 0 calc($unit * 8);

    &--iconOnly {
        padding: 0 calc($unit * 4);
    }
}

/**
 * Button icons
 */

.btn__icon {
}

.btn__icon--left {
    margin-right: calc($unit * 4);
}

.btn__icon--right {
    margin-left: calc($unit * 4);
}
