/*------------------------------------*\
    # block.proposal-block-text
\*------------------------------------*/

.proposal-block-text {
    position: relative;
    margin: calc($unit * 10) 0;
    padding-left: calc($unit * 6);

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: color($color-dark a(0.2));
    }
}
