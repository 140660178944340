/*------------------------------------*\
    # component.loading
\*------------------------------------*/

.loading {
    display: inline-block;
    position: relative;
    height: calc($unit * 11);
    width: calc($unit * 11);
}

.loading div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: color($color-primary lightness(+20%));
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading div:nth-child(1) {
    left: 6px;
    animation: loading1 0.6s infinite;
}

.loading div:nth-child(2) {
    left: 6px;
    animation: loading2 0.6s infinite;
}

.loading div:nth-child(3) {
    left: 26px;
    animation: loading2 0.6s infinite;
}

.loading div:nth-child(4) {
    left: 45px;
    animation: loading3 0.6s infinite;
}

.loading--page {
    position: 'absolute';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes loading1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes loading3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes loading2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
