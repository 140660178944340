/*------------------------------------*\
    # component.title
\*------------------------------------*/

.title {
    font-weight: $type-weight-bold;
}

.title--large {
    @mixin type-scale $type-xlarge, 10;
}

.title--medium {
    @mixin type-scale $type-medium, 7;
}
