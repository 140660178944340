/*------------------------------------*\
    # component.general-info
\*------------------------------------*/

.general-info {
}

.general-info-settings {
    background: $color-black;
}

.general-info-settings__wrapper {
    position: relative;
    display: flex;

    padding-top: calc($unit * 6);
    padding-bottom: calc($unit * 8);

    margin-bottom: calc($unit * 10);

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc($unit * 8);
        right: calc($unit * 8);
        height: 1px;
        background: color(white a(0.1));
    }
}

.general-info-settings__wrapper > div {
    min-width: 120px;
    margin-right: calc($unit * 6);
}

.general-info__fromto {
    lost-flex-container: row;
    margin-bottom: calc($unit * 10);
    padding: calc($unit * 10) 0;

    border-bottom: 1px solid color($color-text a(0.1));
}

.general-info__fromto > div {
    lost-column: 1/2;
}

.general-info__companies {
}

.general-info__currency {
}

.general-info__clientinfo {
}
