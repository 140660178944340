/*------------------------------------*\
    # block.footer
\*------------------------------------*/

.footer {
    padding: 5vh 0;
    margin: 7.5vh 0 0 0;
    border: 1px solid color($color-dark a(0.1));
}

.footer__content {}

.footer__content p {
    @mixin type-scale $type-small,
    5;
    color: color($color-dark a(0.5));

    a {
        display: inline-block;
    }

    @media print {
        color: black;

        a {
            color: #1e1ed2;
        }
    }
}