/*------------------------------------*\
    # plugins.fontface
\*------------------------------------*/

@font-face {
    font-family: 'Aeonik';
    src: url('../assets/fonts/Aeonik-Bold/Aeonik-Bold.eot?#iefix');
    src: url('../assets/fonts/Aeonik-Bold/Aeonik-Bold.eot?#iefix') format('eot'),
        url('../assets/fonts/Aeonik-Bold/Aeonik-Bold.woff2') format('woff2'),
        url('../assets/fonts/Aeonik-Bold/Aeonik-Bold.woff') format('woff'),
        url('../assets/fonts/Aeonik-Bold/Aeonik-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.eot?#iefix');
    src: url('../assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.eot?#iefix')
            format('eot'),
        url('../assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.woff2')
            format('woff2'),
        url('../assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.woff')
            format('woff'),
        url('../assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.ttf')
            format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.eot?#iefix');
    src: url('../assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.eot?#iefix')
            format('eot'),
        url('../assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.woff2')
            format('woff2'),
        url('../assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.woff')
            format('woff'),
        url('../assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.ttf')
            format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../assets/fonts/Aeonik-Regular/Aeonik-Regular.eot?#iefix');
    src: url('../assets/fonts/Aeonik-Regular/Aeonik-Regular.eot?#iefix')
            format('eot'),
        url('../assets/fonts/Aeonik-Regular/Aeonik-Regular.woff2')
            format('woff2'),
        url('../assets/fonts/Aeonik-Regular/Aeonik-Regular.woff') format('woff'),
        url('../assets/fonts/Aeonik-Regular/Aeonik-Regular.ttf')
            format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../assets/fonts/Aeonik-LightItalic/Aeonik-LightItalic.eot?#iefix');
    src: url('../assets/fonts/Aeonik-LightItalic/Aeonik-LightItalic.eot?#iefix')
            format('eot'),
        url('../assets/fonts/Aeonik-LightItalic/Aeonik-LightItalic.woff2')
            format('woff2'),
        url('../assets/fonts/Aeonik-LightItalic/Aeonik-LightItalic.woff')
            format('woff'),
        url('../assets/fonts/Aeonik-LightItalic/Aeonik-LightItalic.ttf')
            format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../assets/fonts/Aeonik-Light/Aeonik-Light.eot?#iefix');
    src: url('../assets/fonts/Aeonik-Light/Aeonik-Light.eot?#iefix')
            format('eot'),
        url('../assets/fonts/Aeonik-Light/Aeonik-Light.woff2') format('woff2'),
        url('../assets/fonts/Aeonik-Light/Aeonik-Light.woff') format('woff'),
        url('../assets/fonts/Aeonik-Light/Aeonik-Light.ttf') format('truetype');
    font-weight: 300;
}
