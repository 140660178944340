/*------------------------------------*\
    # component.proposal-item
\*------------------------------------*/

.proposal-item {
    padding: calc($unit * 8) calc($unit * 8) calc($unit * 4) calc($unit * 8);
    border-radius: $global-radial-large;
    background: white;
    @mixin shadow;

    & + & {
        margin-top: 2.5vh;
    }
}

.proposal-item__info {
    lost-flex-container: row;
}

.proposal-item__main {
    lost-column: 6/12;
}

.proposal-item__title {
    display: block;
    margin-bottom: calc($unit * 8);
}

.proposal-item__amount {
    lost-column: 3/12;
}

.proposal-item__client-status {
    lost-column: 3/12;
}

.proposal-item__year {
    flex: 1;
}

.proposal-item__actions {
    display: flex;
    justify-content: flex-start;
    margin-top: calc($unit * 8);
    padding-top: calc($unit * 4);
    border-top: 1px solid color($color-dark a(0.1));
}

.proposal-item__actions__item {
}
