/*------------------------------------*\
    # component.btn-group
\*------------------------------------*/

.btn-group {
    /**
     * Btngroup base
     */
    display: flex;
}

.btn-group--simple .btn {
    margin-right: calc($unit * 2);

    &:last-child {
        margin-right: 0;
    }
}
