/*------------------------------------*\
    # component.list
\*------------------------------------*/

.list {
}

.list__wrapper {
    background: transparent;
}

.list--inline {
    lost-utility: clearfix;

    & > * {
        float: left;
        margin-right: calc($unit * 2);
    }
}

.list--bordered {
    & > * {
        padding: calc($unit * 2) 0;
        border-bottom: 1px solid color(white blackness(+20%));
    }
}

.list--commaseparated {
    & > * {
        display: inline;

        &:after {
            content: ',';
        }

        &:last-child {
            &:after {
                content: '';
            }
        }
    }
}
