/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    position: relative;
}

/* Container that holds the whole backend app */
.container--app {
    min-width: 920px;
}

/* Container that holds the public prosal */
.container--public {
}

.container--dark {
    background-color: #182a3f;
}

.container--empty {
    height: 100vh;
}
