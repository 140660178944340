/*------------------------------------*\
    # component.service-prices
\*------------------------------------*/

.service-prices {
    text-align: right;
    width: 100%;
}

.service-prices > div {
}

.service-prices p {
    @mixin type-scale $type-tiny, 4;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: color($color-text a(0.6));
}

.service-prices__price {
}

.service-prices__discount {
}

.service-prices__total {
}
