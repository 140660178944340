/*------------------------------------*\
    # component.unit-item
\*------------------------------------*/

.unit-item {
    background: color(black a(0.02));
    padding: calc($unit * 1) calc($unit * 6);
    lost-utility: clearfix;

    &:nth-child(odd) {
        background: white;
    }
}

.unit-item__name {
    float: left;
    text-align: left;
    width: 40%;
}

.unit-item__actions {
    float: right;
    text-align: left;
}
