/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;

    display: inline-block;
    vertical-align: middle;
    width: 100%;

    /* Prefixed -webkit to remove border radius on iOS */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;
    font-family: $ff-sans;

    border-radius: $global-radial;
    background: color($color-dark a(0.05));
    color: $color-text;
    transition: all 0.1s ease-in;

    &::placeholder {
        color: color($color-text a(0.3));
    }

    &:focus {
        border-color: transparent;
        background: color($color-dark a(0.1));
        outline: 0;

        &::placeholder {
            color: color($color-text a(0.5));
        }
    }

    /* Default input sizes. Add input--sml for small override, etc.  */
    @mixin type-scale $type-base, 4;
    height: calc($unit * 10);
    line-height: calc($unit * 6);
    box-shadow: 0 0 10px color($color-dark a(0.05)) inset;
}

/* Input sizes - not used still in the project, but will need to make microforms etc. */
/* .input--sml {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 6);
    line-height: calc($unit * 6);
} */

/* Input widths */
.input--w-micro {
    max-width: 72px;
}

/* Input styles */
.input--dark {
    color: color(white a(0.7));
    background: $color-black;
    border: 1px solid color(white a(0.13));

    &:hover,
    &:focus {
        border-color: color(white a(0.7));
    }

    &:focus {
        box-shadow: 0 0 5px black;
    }
}

/* Input types */
.input--text {
    padding: calc($unit * 2) calc($unit * 4);
}

/* Supersized inputs with fluid typography */
.input--supersized {
    /* Reset default styles */
    height: auto;
    line-height: auto;

    font-size: responsive 20px 31px; /* $type-medium, $type-xlarge */
    font-range: 320px 1440px; /* viewport widths between which font-size is fluid */

    line-height: responsive 24px 36px;
    line-height-range: 320px 1440px;
}

.input--supersized--negative {
    color: white;
}

.input--number {
    padding-right: 0;
}

.input--select {
    padding: calc($unit * 2) calc($unit * 4);
}

.input--select--multiple {
    min-height: 160px;
}

.input--textarea {
    min-height: 160px;
    resize: vertical;
    padding: calc($unit * 4);
    white-space: pre-wrap;
}

.input--radio {
    & + label {
        &:before {
            border-radius: 50%;
        }
    }
}

.input--check,
.input--radio {
    position: absolute;
    left: -99999px;
    opacity: 0;

    & + label {
        padding-left: calc($unit * 1);

        &:before {
            content: '';
            display: block;
            float: left;
            width: calc($unit * 3);
            height: calc($unit * 3);
            margin-top: 6px;

            transition: all 0.2s ease-in-out;

            border: 1px solid color(white blackness(+30%));
        }
    }

    &:focus {
        & + label {
            &:before {
                box-shadow: 0 0 5px $color-primary;
            }
        }
    }

    &:checked {
        & + label {
            &:before {
                background: $color-primary;
                border-color: transparent;
            }
        }
    }
}
