/*------------------------------------*\
    # component.total-prices
\*------------------------------------*/

.total-prices {
    max-width: 30%;
    margin-left: auto;
}

.total-prices__subtotal {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc($unit * 4);
}

.total-prices__subtotal--total {
    padding-top: calc($unit * 4);
    border-top: 1px solid color($color-dark a(0.1));
    font-weight: $type-weight-bold;
}

.total-prices__subtotal__label {
    @mixin type-scale $type-tiny, 4;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: color($color-text a(0.6));
}

.total-prices__subtotal__value {
    display: flex;
    align-items: center;
}
