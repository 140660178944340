/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    margin-bottom: calc($unit * 8);
}

/* Field label */
.field__lbl {
    display: block;
    padding-bottom: calc($unit * 2);
    opacity: 0.7;

    @mixin type-scale $type-micro, 4;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

/* Field group holds input and buttons together */
.field__group {
    display: table;

    & > * {
        display: table-cell;
        vertical-align: top;
    }
}

/* Icon on the left or right of the input */
.field__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.field__icon--left {
    left: 0;
}

.field__icon--right {
    right: 0;
}

.field--hidden {
    display: none;
}
